import React from 'react';
import { amplitudeLog } from '../Lib/ampletude';
import * as styles from '../styles/mobileBottomPopup.module.css';
import { getPlatform } from '../utils';

const MobileBottomPopup = (props) => {
  const { isVisible, onCancel } = props;

  const handleDownClick = (eventName: string) => {
    return () => {
      amplitudeLog(`앱 설치 버튼`, {
        path: '제품 검색',
        type: getPlatform(),
      });
      const gotoLink = 'https://momguide.page.link/landingapp';
      window.open(gotoLink);
    };
  };

  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        bottom: '50px',
        maxWidth: '422px',
        display: 'flex',
        justifyContent: 'center',
        height: '90px',
        visibility: isVisible ? 'inherit' : 'hidden',
      }}
    >
      <div
        style={{
          width: '95%',
          height: 'fit-content',
          display: 'flex',
          backgroundColor: '#424242e6',
          borderRadius: '6px',
          position: 'relative',
          justifyContent: 'center',
        }}
      >
        <div style={{ margin: '15px 32px' }}>
          <div
            style={{
              textAlign: 'center',
              fontSize: 12,
              lineHeight: '14px',
              color: '#ffffff',
              whiteSpace: 'pre-line',
            }}
          >
            필터, 찜하기, 리뷰보기 등 더 편리한 사용을 원하신다면{'\n'}
            <span style={{ fontWeight: 700, color: '#88D8CF' }}>맘가이드 앱을 다운받아 보세요.😊</span>
          </div>
          <div className={styles.storeWrapper}>
            {/* <p className={styles.download}>맘가이드 어플 다운로드</p> */}
            <img
              alt="앱스토어 링크"
              onClick={handleDownClick('m_appstore')}
              src={'/images/main/button_appstore.png'}
              className={styles.store}
            />
            <img
              alt="플레이스토어 링크"
              onClick={handleDownClick('m_playstore')}
              src={'/images/main/button_googleplay.png'}
              className={styles.store}
            />
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            right: 0,
          }}
          onClick={onCancel}
        >
          <img
            style={{
              width: 29,
              height: 29,
              cursor: 'pointer',
              imageRendering: '-webkit-optimize-contrast',
            }}
            src="/images/popup/cancel_btn.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default MobileBottomPopup;
