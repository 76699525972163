import React, { CSSProperties, useState } from 'react';
// import { useQueryParam, StringParam } from 'use-query-params'
import AppContainerView from '../../components/AppContainerView';
import MobileHeader from '../../components/MobileHeader';
import MobileTemplate from '../../components/MobileTemplate';
import { images } from '../../data';
import * as styles from '../../styles/ewgGrade/ewgGrade.module.css';
import { IModel } from 'momguide-interface';
import { PageProps } from 'gatsby';

const data = [
  {
    label: '정보 없음',
    icon: 'ingredientGradeX',
    type: 'v1',
  },
  {
    label: '매우 낮은 위험도',
    icon: 'ingredientGradeA',
    type: 'v1',
  },
  {
    label: '낮은 위험도',
    icon: 'ingredientGradeB',
    type: 'v1',
  },
  {
    label: '중간 위험도',
    icon: 'ingredientGradeC',
    type: 'v1',
  },
  {
    label: '높은 위험도',
    icon: 'ingredientGradeD',
    type: 'v1',
  },
  {
    label: '매우 높은 위험도',
    icon: 'ingredientGradeF',
    type: 'v1',
  },
  {
    label: '정보 없음',
    icon: 'ingredientGradeFA',
    type: 'v2',
  },
  {
    label: '낮은 위험도',
    icon: 'ingredientGradeFB',
    type: 'v2',
  },
  {
    label: '중간 위험도',
    icon: 'ingredientGradeFC',
    type: 'v2',
  },
  {
    label: '높은 위험도',
    icon: 'ingredientGradeFD',
    type: 'v2',
  },
];

const data2 = [
  {
    label: '근거 데이터 부족',
    icon: 'ingredientDataC',
    type: 'v2',
  },
  {
    label: '근거 데이터 보통',
    icon: 'ingredientDataB',
    type: 'v2',
  },
  {
    label: '근거 데이터 충분',
    icon: 'ingredientDataA',
    type: 'v2',
  },
];
const Item = ({ item, imgStyle }: { item: { label: string; icon: string; type: number }; imgStyle?: CSSProperties }) => {
  return (
    <div className={styles.itemContainer}>
      <img
        style={{
          marginRight: 13,
          width: 30,
          height: 30,
          ...imgStyle,
        }}
        src={images[item.icon]}
      />
      <span style={{ color: '#000000', fontWeight: 300, fontSize: 12 }}>{item.label}</span>
    </div>
  );
};

const EWGGrade = (props: PageProps) => {
  const { location } = props;
  const paramsObj = new URLSearchParams(location.search);

  // const [version, setVersion] = useQueryParam('version', StringParam)

  const [version, setVersion] = useState(paramsObj.get('version'));
  const headerText =
    version === 'v1'
      ? '미국의 비영리 환경연구 단체 EWG(Environmental Working Group)는 공신력 있는 기관에서 수집된 자료를 기반으로 성분에 대한 시각적인 등급을 매기고 있습니다. EWG에서는 Guide To Healthy Cleaning을 통해 세제, 세정제 등 생활화학제품과 그 성분에 대해서 아래와 같이 등급을 매기고 있습니다.'
      : '미국의 비영리 환경연구 단체 EWG(Environmental Working Group)는 공신력 있는 기관에서 수집된 자료를 기반으로 성분에 대한 시각적인 등급을 매기고 있습니다. EWG에서는 화장품에 대한 성분 등급정보를 Skin Deep Database를 통해 아래와 같이 제공하고 있습니다.';
  const middleText =
    version === 'v1'
      ? 'A에서 F로 갈수록 독성이나 유해성이 높은 성분입니다. 또한 성분에 대한 자료가 부족하거나 성분명이 불명확할 때는 나쁜 등급을 매기고 있습니다.'
      : '아울러 위와 같은 등급을 도출하기 위해 사용된 평가 데이터의 양에 대한 정보도 함께 제공하고 있습니다. 이에 따라 특정 성분이 낮은 주의를 요하는 등급이더라도 데이터 등급이 낮다면 안전하다고 판단하기 어렵습니다.\n\n맘가이드에서는 성분 등급 우측에 데이터의 등급을 아래와 같이 표기합니다.';

  return (
    <AppContainerView>
      <MobileTemplate>
        <MobileHeader title="EWG 등급 기준" />
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <span className={styles.text} style={{ marginBottom: 5 }}>
            {headerText}
          </span>

          <div />
          {data
            .filter((v: any, index: number) => {
              return v.type == version;
            })
            .map((item, index) => {
              return <Item key={`${item.icon}_${item.type}` + item.label} item={item} />;
            })}

          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <span className={styles.text} style={{ marginTop: 26 }}>
              {middleText}
            </span>
            <div>
              {version !== 'v1'
                ? data2.map((item) => {
                    return <Item imgStyle={{ imageRendering: 'pixelated' }} key={`${item.icon}_${item.type}` + item.label} item={item} />;
                  })
                : null}
            </div>
          </div>
        </div>
      </MobileTemplate>
    </AppContainerView>
  );
};

export default EWGGrade;
