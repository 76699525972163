import { navigate } from 'gatsby-link';
import React from 'react';
import { images } from '../data';
import { useIsMobile } from './mediaQuery';

export const LeftArrow = (props: any) => {
  const { color = '#898989', onClick, width, height, style } = props;
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M7 1.17676L1.26546 6.60989L7 12.2407"
        stroke={color}
        strokeWidth="2.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CancelBtn = (props: any) => {
  const { color = '#898989', onClick, width = 30, height = 30, style } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3335 19.0278L11.0475 10.5952L19.3335 19.0278Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3335 10.5952L11.0475 19.0278L19.3335 10.5952Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const MobileHeader = (props: any) => {
  const {
    title,
    onShare,
    TitleComponent,
    backgroundColor = '#ffffff',
    RightComponent,
    titleStyle,
    arrowColor = '#898989',
    isHomeButton,
  } = props;
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',

        position: 'sticky',
        height: 60,
        alignItems: 'center',
        top: isMobile ? 0 : 90,
        zIndex: 1,
        background: backgroundColor,
      }}
    >
      <LeftArrow
        style={{ position: 'absolute', left: 20, cursor: 'pointer' }}
        width={15}
        height={15}
        onClick={() => history.back()}
        color={arrowColor}
      />
      {isHomeButton && (
        <img
          style={{
            position: 'absolute',
            left: 55,
            cursor: 'pointer',
            width: 25,
            height: 25,
          }}
          src={images.home}
          onClick={() => {
            navigate('/search');
          }}
        />
      )}
      {/* <img
        style={{
          width: 15,
          height: 15,
          position: "absolute",
          left: 20,
          cursor: "pointer",
        }}
        // src={isOpen ? images.up : images.down}
        // resizeMode="contain"
        src="/images/arrow_left.svg"
        onClick={() => history.back()}
      /> */}

      <div>
        {TitleComponent ? (
          TitleComponent
        ) : (
          <span
            style={{
              fontSize: 14,
              fontWeight: 700,
              color: '#000000',
              ...titleStyle,
            }}
          >
            {title}
          </span>
        )}
      </div>
      {onShare && (
        <div
          id="kakaoShare"
          style={{
            position: 'absolute',
            right: 27,
            cursor: 'pointer',
          }}
          onClick={onShare}
        >
          <img
            style={{
              width: 25,
              height: 25,
            }}
            src={images.share}
            alt="kakao-share-icon"
          />
        </div>
      )}
      {RightComponent && (
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            right: 17,
            cursor: 'pointer',
          }}
        >
          {RightComponent}
        </div>
      )}
    </div>
  );
};
export default MobileHeader;
