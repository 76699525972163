import React, { useEffect, useState } from 'react';
import { useIsMobile, useMounted } from './mediaQuery';
import * as styles from '../styles/mobileTemplate.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { closePopup, RootState } from '../state/rootStore';
import { checkMobile, getPlatform } from '../utils';
import MobileBottomPopup from './MobileBottomPopup';
import { useCookies } from 'react-cookie';
import { amplitudeLog } from '../Lib/ampletude';

const MobilePopup = (props) => {
  const appLink = 'https://momguide.page.link/landingapp';
  const appLinkApple = 'https://bAR9EnztZ0iOB5hDeuOPxg.adtouch.adbrix.io/api/v1/click/I6GmRsrss0isvp1Tuja6QQ';
  const appLinkGoogle = 'https://bAR9EnztZ0iOB5hDeuOPxg.adtouch.adbrix.io/api/v1/click/Z6D1ml67wEm31WspMWb6pw';
  const { popupVisible, popupText, popupButtonText, popupButtonText2, popupLastPath } = useSelector(
    (rootState) => rootState,
  );
  const dispatch = useDispatch();

  const handleDownClick = (eventName?: string) => {
    amplitudeLog(`앱 설치 버튼`, {
      path: popupLastPath,
      type: getPlatform(),
    });

    let gotoLink = appLink;

    const deviceType = checkMobile();
    if (deviceType === 'android') {
      gotoLink = appLinkGoogle;
    } else if (deviceType === 'ios') {
      gotoLink = appLinkApple;
    }
    window.open(gotoLink);
  };

  if (!popupVisible) return null;
  return (
    <div className={styles.popupContainer}>
      <div className={styles.popupBoxContainer}>
        <div className={styles.contentTextContainer}>
          <span className={styles.contentText}>{popupText}</span>
        </div>

        <div className={styles.buttonTextContainer} onClick={() => dispatch(closePopup())}>
          <span className={styles.buttonText}>{popupButtonText}</span>
        </div>

        <div
          className={styles.buttonTextContainer}
          onClick={async () => {
            handleDownClick();
            await dispatch(closePopup());
          }}
        >
          <span className={styles.buttonText}>{popupButtonText2}</span>
        </div>
      </div>
    </div>
  );
};

export default function MobileTemplate(props) {
  const { tabIndex, children } = props;
  const appType = useSelector((state: RootState) => state.appType);
  const isMobile = useIsMobile();
  const [isVisible, setIsVisible] = useState(true);
  const [cookies, setCookie] = useCookies([`isBottomPopup`]);

  const isMounted = useMounted();

  if (!isMounted) return <></>;

  return (
    <div
      className={isMobile ? styles.mobileContainer : styles.pcContainer}
      style={{ flexDirection: 'row', display: 'flex' }}
    >
      <div color-theme={appType} className={styles.appContainer} style={{ minHeight: isMobile ? '100vh' : '90vh' }}>
        {/* {Header} */}
        {children}
        <MobilePopup />

        <MobileBottomPopup
          isVisible={!cookies.isBottomPopup}
          onCancel={() => {
            const expires = new Date();
            expires.setMinutes(expires.getMinutes() + 10);
            setCookie('isBottomPopup', true, { expires });
          }}
        />
      </div>
    </div>
  );
}
