import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderComp, { HeaderOnlySearch } from '../components/header';

import { useIsMobile, useMounted } from '../components/mediaQuery';

import WebLeftView from './searchComp/WebLeftView';
import { amplitudeLog } from '../Lib/ampletude';
import { fetchGetAppEvent, RootState } from '../state/rootStore';

import * as styles from '../styles/search/search.module.css';
import { getPlatform } from '../utils';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';

/**
 * src/components/layout.tsx Layout과 비슷한 역할의 컴포넌트
 * @param param0
 * @returns
 */
export default function AppContainerView({ children, pageTitle }: any) {
  const currentEvent = useSelector((state: RootState) => state.appEvent);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isMounted = useMounted();

  const { title, copyRight, keywords, description, siteUrl, appLinkGoogle, appLinkApple } = useSiteMetadata();

  useEffect(() => {
    const init = async () => {
      dispatch(fetchGetAppEvent());
    };
    init();
  }, []);

  const handleDownClick = (eventName: string) => {
    return () => {
      amplitudeLog(`앱 설치 버튼`, {
        path: '제품 검색',
        type: getPlatform(),
      });
      // TODO: app store/google play 분기
      // apple: appLinkApple
      // android, google: appLinkGoogle
      let gotoLink = appLinkGoogle;
      if (eventName.includes('app')) {
        // apple
        gotoLink = appLinkApple;
      } else if (eventName.includes('play')) {
        // google
        gotoLink = appLinkGoogle;
      }

      window.open(gotoLink);
    };
  };
  // if (isMobile) return <div />

  // if (!isMounted) return null
  return (
    <div className={isMobile ? styles.mContainer : styles.pcContainer}>
      {!isMobile ? (
        <>
          <div className={styles.backgroundImg} />
          <HeaderOnlySearch bold="search" />
          <div style={{ height: 90 }} />
          <WebLeftView currentEvent={currentEvent} handleDownClick={handleDownClick} />
        </>
      ) : null}

      {children}
    </div>
  );
}
