// extracted by mini-css-extract-plugin
export var appContainer = "mobileTemplate-module--appContainer--e4773";
export var buttonText = "mobileTemplate-module--buttonText--23644";
export var buttonTextContainer = "mobileTemplate-module--buttonTextContainer--26fda";
export var contentText = "mobileTemplate-module--contentText--c384b";
export var contentTextContainer = "mobileTemplate-module--contentTextContainer--b47b6";
export var mobileContainer = "mobileTemplate-module--mobileContainer--d908f";
export var pcContainer = "mobileTemplate-module--pcContainer--ae943";
export var popupBoxContainer = "mobileTemplate-module--popupBoxContainer--7b14a";
export var popupContainer = "mobileTemplate-module--popupContainer--f2335";
export var store = "mobileTemplate-module--store--e67ae";
export var storeWrapper = "mobileTemplate-module--storeWrapper--7741b";